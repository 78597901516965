<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <BannerForm v-if="responseData" :page-data="responseData" />
    </Layout>
  </div>
</template>

<script>
import Vue from 'vue';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header.vue';
import BannerForm from '@/components/banner/BannerForm.vue';

export default Vue.extend({
  components: {
    Layout,
    PageHeader,
    BannerForm
  },
  async created() {
    console.log(this.$route.params);
    await this.$store
      .dispatch('banner/bannerDetailInfo', {
        id: this.$route.params.idx
      })
      .then(res => {
        console.log(res);
        this.responseData = res;
      });
  },
  data() {
    return {
      title: 'Banner',
      items: [
        {
          text: 'Banner Add',
          active: true
        }
      ],
      responseData: null
    };
  }
});
</script>
